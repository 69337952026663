<template>
  <div class="table-setting">
    <div class="table-setting-search">
      <el-input
        v-model="searchForm.connectionTableName"
        placeholder="输入表名称"
        type="primary"
        clearable
        size="small"
        style="width: 250px"
        @input="init"
      />
      <el-input
        v-model="searchForm.remark"
        placeholder="输入表描述"
        type="primary"
        clearable
        size="small"
        style="width: 250px; margin: 0 20px"
        @input="init"
      />
      <el-button type="primary" size="small" @click="init">
        搜索
      </el-button>
    </div>
    <div class="table-setting-btns">
      <el-button type="primary" size="small" @click="() => $refs.importTableDialog.open()">
        新建表
      </el-button>
    </div>
    <el-table :data="dataSource" highlight-current-row border>
      <el-table-column label="ID" prop="id" />
      <el-table-column label="表名称" prop="connectionTableName" show-overflow-tooltip />
      <el-table-column label="表描述" prop="remark" show-overflow-tooltip />
      <el-table-column label="实体" prop="tableType" show-overflow-tooltip />
      <el-table-column label="分组" prop="groupId" show-overflow-tooltip />
      <el-table-column label="开启分页" prop="isPage">
        <template v-slot:default="{row}">
          {{ row.isPage === '0' ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="树形结构" prop="isTree">
        <template v-slot:default="{row}">
          {{ row.isTree === '0' ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createdAt" show-overflow-tooltip />
      <el-table-column label="更新时间" prop="updatedAt" show-overflow-tooltip />
      <el-table-column label="操作" width="200">
        <template v-slot="{row}">
          <el-button size="small" type="text" @click="$router.push(`/table-setting/${row.id}`)">
            查看字段
          </el-button>
          <el-button size="small" type="text" @click="handlerEdit(row)">
            编辑
          </el-button>
          <el-button size="small" type="text" @click="handlerRemove(row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <ImportTableDialog ref="importTableDialog" @success="init" />
    <el-dialog :visible="visible" title="编辑" @click="handleCloseDialog">
      <el-form ref="form" :model="editForm" label-width="80px" label-position="left">
        <el-form-item prop="connectionTableName" label="名称">
          <el-input v-model="editForm.connectionTableName" size="small" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="editForm.remark" :rows="4" type="textarea" size="small" placeholder="请输入字段描述" />
        </el-form-item>
        <el-form-item label="分组">
          <el-input v-model="editForm.groupId" size="small" placeholder="分组" />
        </el-form-item>
        <el-form-item label="开启分页">
          <el-checkbox v-model="editForm.isPage" false-label="1" true-label="0" />
        </el-form-item>
        <el-form-item label="树形结构">
          <el-checkbox v-model="editForm.isTree" false-label="1" true-label="0" />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <el-button size="small" @click="handleCloseDialog">
          取消
        </el-button>
        <el-button size="small" type="primary" @click="handleSaveTable">
          保存
        </el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ImportTableDialog from './component/import-table-dialog';
import {getTableList, removeTable, tableSave} from "@/api/table-set";

export default {
  name: 'TableSetting',
  components: {
    ImportTableDialog,
  },
  data () {
    return {
      dataSource: [],
      visible: false,
      editForm: {},
      searchForm: {
        connectionTableName: '',
        remark: ''
      }
    };
  },
  mounted () {
    this.init();
  },
  methods: {
    handleCloseDialog () {
      this.visible = false;
    },
    async handlerRemove (row) {
      await this.$confirm('确定删除当前行吗？');
      await removeTable({ schemaId: row.id });
      this.$message.success('删除成功');
      this.init();
    },
    handlerEdit (row) {
      this.editForm = row;
      this.visible = true;
    },
    async init () {
      const {list} = await getTableList(this.searchForm);
      this.dataSource = list;
    },
    async handleSaveTable () {
      await tableSave(this.editForm);
      this.$message.success('编辑成功');
      this.init();
      this.handleCloseDialog();
    }
  }
};
</script>

<style lang="scss">
.table-setting {
  background: #ffffff;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 20px;
  &-search {
    > {
      margin: 0 10px;
    }
  }
  &-btns {
    margin: 20px 0;
  }
}
</style>
