<template>
  <div v-loading="loading">
    <el-table :data="list" highlight-current-row border max-height="500">
      <el-table-column show-overflow-tooltip label="字段列名" prop="columnName" />
      <el-table-column label="字段描述" prop="columnNameLabel" width="171">
        <template #default="{row}">
          <el-input v-model="row.columnNameLabel" placeholder="字段描述" size="mini" style="width: 140px" />
        </template>
      </el-table-column>
      <el-table-column label="字段类型" prop="columnType" />
      <el-table-column fixed="right" width="80" label="操作">
        <template v-slot:default="scope">
          <el-button size="small" type="text" @click="onRemove(scope)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {tablePreview} from "@/api/table-set";

export default {
  name: 'TablePreviewDialog',
  props: {
    selectIds: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      list: [],
      loading: false,
    };
  },
  methods: {
    async init () {
      this.loading = true;
      this.list = await tablePreview(this.selectIds.join(','));
      this.loading = false;
    },
    onRemove ({$index}) {
      this.list.splice($index, 1);
    },
    getList () {
      return this.list;
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
