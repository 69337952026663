<template>
  <el-form ref="form" :rules="formRule" :model="formModel" label-position="right" label-width="80px">
    <el-form-item label="名称" prop="connectionTableName">
      <el-input v-model="formModel.connectionTableName" size="small" placeholder="表名称" />
    </el-form-item>
    <el-form-item prop="remark" label="描述">
      <el-input v-model="formModel.remark" :rows="4" size="small" placeholder="表名称" type="textarea" />
    </el-form-item>
    <el-form-item prop="groupId" label="分组">
      <el-input v-model="formModel.groupId" size="small" placeholder="表名称" />
    </el-form-item>
    <el-form-item prop="isPage" label="开启分页">
      <el-switch v-model="formModel.isPage" :active-value="0" :inactive-value="1" size="small" />
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'TableBaseInfo',
  data () {
    return {
      formRule: {
        connectionTableName: [{ required: true, message: '名称不能为空' }],
      },
      formModel: {
        connectionTableName: '',
        remark: '',
        isPage: 0,
        groupId: '',
      }
    };
  },
  methods: {
    async getFormModel () {
      try {
        await this.$refs.form.validate();
        return this.formModel;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
