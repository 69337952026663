<template>
  <el-dialog
    :visible="visible"
    :close-on-click-modal="false"
    title="新建表"
    custom-class="import-table-dialog"
    width="800px"
    @close="close"
  >
    <div style="margin: 0 auto 20px auto">
      <el-steps :space="400" :active="stepActive" simple>
        <el-step title="1、选择表" />
        <el-step title="2、字段预览" />
        <el-step title="3、基础信息" />
      </el-steps>
    </div>
    <div v-show="stepActive === 1">
      <div style="margin-bottom: 20px">
        <el-input v-model="formModel.connectionTableName" size="small" placeholder="搜索表名" style="width: 250px" />
      </div>
      <el-table ref="table" :data="dataSource" max-height="400" @select="handleTableSelect" @select-all="handlerSelectAll">
        <el-table-column type="selection" align="center" />
        <el-table-column label="表名称" prop="connectionTableName" />
        <el-table-column label="表描述" prop="remark" />
        <el-table-column label="创建时间" prop="createdAt" />
        <el-table-column label="更新时间" prop="updatedAt" />
      </el-table>
    </div>
    <TablePreview v-show="stepActive === 2" ref="tablePreview" :select-ids="selects" />
    <TableBaseInfo v-show="stepActive === 3" ref="tableBaseInfo" />
    <template v-slot:footer>
      <el-button size="small" @click="close">
        取消
      </el-button>
      <el-button v-if="stepActive > 1" size="small" type="primary" @click="stepActive--">
        上一步
      </el-button>
      <el-button size="small" type="primary" @click="nextStep">
        {{ nextText }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {getDataTableSource, tableSave} from '@/api/table-set';
import TablePreview from './preview';
import TableBaseInfo from './table-base-info';

export default {
  name: 'ImportTableDialog',
  components: {
    TablePreview,
    TableBaseInfo,
  },
  data () {
    return {
      visible: false,
      selects: [],
      dataSource: [],
      stepActive: 1,
      formModel: {
        connectionTableName: ''
      }
    };
  },
  computed: {
    nextText () {
      return this.stepActive < 3 ? '下一步' : '确定';
    }
  },
  watch: {
    formModel: {
      deep: true,
      handler () {
        this.init();
      }
    }
  },
  methods: {
    async nextStep () {
      if (this.stepActive === 1 && this.selects.length === 0) {
        this.$message.error('请选择数据表');
        return;
      }
      if (this.stepActive === 3) {
        const form = await this.$refs.tableBaseInfo.getFormModel();
        if (!form) return false;
        const columnSchemaList = this.$refs.tablePreview.getList();
        tableSave({
          ...form,
          columnSchemaList,
        }).then(() => {
          this.$message.success('创建成功');
          this.close();
          this.$emit('success');
        });
      }
      if (this.stepActive < 3) {
        this.stepActive++;
      }
      if (this.stepActive === 2) {
        this.$refs.tablePreview.init();
      }
    },
    close () {
      this.visible = false;
    },
    async open () {
      this.visible = true;
      if (this.dataSource.length !== 0) return false;
      await this.init();
    },
    async init () {
      const {list} = await getDataTableSource(this.formModel);
      this.dataSource = list;
      this.dataSource.forEach(item => {
        const index = this.selects.indexOf(item.schemaId);
        if (index !== -1) {
          this.$nextTick(() => {
            this.$refs.table.toggleRowSelection(item, true);
          });
        }
      });
    },
    handlerSelectAll (all) {
      this.dataSource.forEach(item => {
        this.handleTableSelect(all.length === 0 ? [] : this.dataSource, item);
      });
    },
    handleTableSelect (all, row) {
      const remove = !all.includes(row);
      const index = this.selects.indexOf(row.schemaId);
      if (remove) {
        this.selects.splice(index, 1);
      } else {
        if (index === -1) this.selects.push(row.schemaId);
      }
    }
  }
};
</script>

<style lang="scss">
.import-table-dialog {
  .el-table__body td {
    padding: 10px 2px;
  }
}
</style>
